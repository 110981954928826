<template>
  <v-container
    fluid
    style="padding: 0"
  >  
    <ProjectHeader
      :filter="filter"
      :active-item-count="totalActive"
      :archived-item-count="totalArchived"
      @update-filter="updateFilter"
    />
    <Loader
      v-if="tableLoadingState"
      class="projects-loader"
    />
    <template v-else-if="projects.length == 0 && !isFilterApplied">
      <v-row class="py-sm-16">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-img
            :src="require('@/assets/png/table-empty-state.png')"
            alt="Table empty state"
            width="100%"
            max-width="438"
          />
        </v-col>
        <v-col cols="12">
          <v-flex class="flex-column align-center">
            <p class="ma-0 font-weight-bold text-h6 text-sm-h5">
              {{ $t('projects.empty_state.title') }}
            </p>
            <p class="mb-0 mt-3">
              {{ $t('projects.empty_state.lets_get_started') }}
            </p>
            <p class="ma-0">
              {{ $t('projects.empty_state.take_the_lead.part1') }}
              <span class="font-weight-medium">{{ $t('projects.empty_state.take_the_lead.part2') }}</span>
              {{ $t('projects.empty_state.take_the_lead.part3') }}
            </p>
          </v-flex>
          <v-flex class="mt-6 align-center justify-center">
            <div>
              <v-btn
                dark
                large
                color="blue"
                class="text-capitalize font-weight-bold"
                :to="{ name: 'ProjectCreateView' }"
              >
                {{ $t('createProject') }} <v-icon
                  class="ml-1"
                  size="16"
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-card
        class="py-6 px-6 mt-3"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <template>
          <v-row
            justify="space-between"
            class="align-center"
          >
            <v-col
              cols="12"
              md="auto"
              class="d-flex align-center"
            >
              <SearchComponent
                :search="search"
                @update:search="search = $event"
              />
              <ProjectFilter
                :filtered-members="this.activeMembers"
                @applyFilters="applyFilters"
              />
            </v-col>
            <v-col
              cols="12"
              md="auto"
            >
              <v-row
                justify="end"
                class="align-center"
              >
                <ToggleView
                  :table="table"
                  @toggle-table="toggleTable"
                />
                <SettingsMenu
                  :filter-items="filterItems"
                  :required-items="['Name', 'Test Runs', 'Users']"
                />
              </v-row>
            </v-col>
          </v-row>
          <ProjectTable
            v-if="table"
            :filtered-headers="filteredHeaders"
            :filtered-items="filteredItems"
            :item-key="itemKey"
            :row-class="rowClass"
            @select-item="useProject"
            @edit-item="editItem"
            @archive-item="confirmArchiveProject"
            @unarchive-item="confirmUnarchiveProject"
            @delete-item="confirmDeleteProject"
            @toggle-star="handleToggleStar"
          />

          <v-row
            v-else-if="!table && filteredItems.length > 0"
            class="mt-6"
          >
            <v-col
              v-for="(item, index) in filteredItems"
              :key="index"
              cols="4"
            >
              <ProjectCard
                :item="item"
                :filter-items="filterItems"
                @select-item="useProject"
                @edit-item="editItem"
                @archive-item="confirmArchiveProject"
                @unarchive-item="confirmUnarchiveProject"
                @delete-item="confirmDeleteProject"
                @toggle-star="handleToggleStar"
              />
            </v-col>
          </v-row>
          <div
            v-else
            class="py-10"
          >
            {{ $t('projects.no_data_available') }}
          </div>
        </template>
      </v-card>
    </template>
    <ProjectDiscardDialog
      v-model="showConfirmDeleteDialog"
      :title="$t('projects.delete_project_dialog.title')"
      @close="closeDeleteDialog"
      @handleConfirmClick="confirmDeleteProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.delete_project_dialog.warning') }}
          </p>
          <p class="text-start">
            {{ $t('projects.delete_project_dialog.recommend') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="archiveProject"
            >
              {{ $t('archive') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="danger"
              @click="deleteProject"
            >
              {{ $t('projects.create_project.close_dialog.confirm_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
    <ProjectDiscardDialog
      v-model="showConfirmArchiveDialog"
      :title="$t('projects.archive_project_dialog.title')"
      @close="closeArchiveDialog"
      @handleConfirmClick="confirmArchiveProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.archive_project_dialog.warning') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="closeArchiveDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="blue"
              @click="archiveProject"
            >
              {{ $t('archive') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
    <ProjectDiscardDialog
      v-model="showConfirmUnarchiveDialog"
      :title="$t('projects.archive_project_dialog.title')"
      @close="closeUnarchiveDialog"
      @handleConfirmClick="confirmUnarchiveProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.archive_project_dialog.warning') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="closeUnarchiveDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="blue"
              @click="unarchiveProject"
            >
              {{ $t('unarchive') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
  </v-container>
</template>

<script>
import ProjectHeader from '@/components/Project/ProjectHeader';
import ProjectFilter from '@/components/Project/ProjectFilter.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import ProjectCard from '@/components/Project/ProjectCard.vue';
import ToggleView from '@/components/Project/ToggleView.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import ProjectTable from '@/components/Project/ProjectTable.vue';
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog';
import { createNamespacedHelpers } from 'vuex';
import ProjectsService from '@/services/api/project';
import { handleNetworkStatusError } from '@/mixins/redirect';
import Loader from '@/components/base/Loader';
import makeOrgService from '@/services/api/org';

const { mapState: mapUserState } = createNamespacedHelpers('user');
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { sleep } from '@/utils/util';

import { items } from '@/constants/data.js';

let makeProjectService

export default {
  name: 'Project',
  components: {
    ProjectHeader,
    ProjectFilter,
    SearchComponent,
    ProjectCard,
    ProjectTable,
    ToggleView,
    SettingsMenu,
    ProjectDiscardDialog,
    Loader,
  },
  mixins: [handleNetworkStatusError],
  data() {
    return {
      handle: this.$route.params.handle,
      selectedProject: {},
      projects: [],
      totalActive: 0,
      totalArchived: 0,
      loaderState: false,
      loading: false,
      filter: 'active',
      isFilterApplied: false,
      search: '',
      items: items,
      table: true,
      options: ['active', 'archived'],
      membersState: {
        isLoading: false,
        hasError: false,
        errorMessage: '',
      },
      activeMembers: [],
      filterItems: [
        { text: this.$t('name'), checked: true },
        { text: this.$t('key'), checked: true },
        { text: this.$t('testRuns'), checked: true },
        { text: this.$t('testCases'), checked: true },
        { text: this.$t('defects'), checked: false },
        { text: this.$t('projectAdmin'), checked: false },
        { text: this.$t('creationDate'), checked: false },
        { text: this.$t('lastChanges'), checked: false },
        { text: this.$t('users'), checked: true },
      ],
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: this.$t('key'),
          align: 'start',
          sortable: true,
          value: 'key',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: this.$t('testRuns'),
          value: 'test',
          sortable: true,
        },
        {
          text: this.$t('testCases'),
          value: 'cases',
          sortable: true,
        },
        {
          text: this.$t('defects'),
          value: 'defects',
          sortable: true,
        },
        {
          text: this.$t('projectAdmin'),
          value: 'projadmin',
          sortable: true,
        },
        {
          text: this.$t('lastChanges'),
          value: 'lastchanges',
          sortable: true,
        },
        {
          text: this.$t('creationDate'),
          value: 'creationdate',
          sortable: true,
        },
        {
          text: this.$t('users'),
          value: 'users',
          sortable: true,
          class: 'rounded-r-lg',
        },
      ],
      itemKey: 'uid',
      rowClass: () => 'project-item',
      showConfirmDeleteDialog: false,
      showConfirmArchiveDialog: false,
      showConfirmUnarchiveDialog: false,
      tableLoadingState: false,
    };
  },
  computed: {

    ...mapUserState(['currentAccount']),
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filterItems.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    filteredItems() {
      let filtered = this.projects;
      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        filtered = filtered.filter((project) => project.name.toLowerCase().includes(searchTerm));
      }
      return filtered;
    },
  },
  mounted() {
    this.init();
  },
  created() {
    makeProjectService = ProjectsService(this.$api);
  },
  methods: {

    useProject(project) {
      this.$router.push({
        name: 'Cases',
        params: {
          handle: this.$route.params.handle,
          key: project.key
        },
      });
    },
    async getProjects(additionalParams = {}, handle) {
      if (!handle) handle = this.handle;
      const searchParams = new URLSearchParams();
      searchParams.set('status', this.filter);
      searchParams.set('includeCount', true);
    
      Object.keys(additionalParams).forEach(key => {
        searchParams.set(key, additionalParams[key]);
      });

      this.loading = true;
      try {
        const response = await makeProjectService.getProjects(handle, searchParams.toString());
        this.projects = response.data.projects;
        this.totalActive = response.data.meta.totalActive;
        this.totalArchived = response.data.meta.totalArchived;
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        this.loading = false;
      }
    },

    async applyFilters(queryParams,filterApplied) {
      this.isFilterApplied = filterApplied;
      this.tableLoadingState = true;
      try {
        await this.getProjects(queryParams);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        this.tableLoadingState = false;
      }

    },

    async init() {
      await this.getMembers(this.handle);
      this.loaderState = false;
      this.tableLoadingState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);

      try {
        
        await this.getProjects(); 
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {
          await sleep(1000); 
          this.loaderState = false; 
          this.tableLoadingState = false;
        };
        hideLoader(); 
      }
    },

    async getMembers(handle) {
      const orgService = makeOrgService(this.$api);
      this.membersState.isLoading = true;
      const per_page = 30;
      const next_page = 0;
      try {
        const response = await orgService.getUsers(handle, { per_page: per_page, next_page: next_page });
        this.activeMembers = response.data.users;
        this.activeUserCount = this.getActiveUserCount;
        this.membersState.isLoading = false;
      } catch (error) {
        this.membersState.isLoading = false;
        this.membersState.hasError = true;
        this.membersState.errorMessage = error.message;
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'members' });
      }
    },
    
    restore() {
      this.filterItems.forEach((item) => {
        item.checked = ['Name', 'Test Runs', 'Users'].includes(item.text);
      });
    },
    archiveItem(item) {
      item.status = 'archived';
    },
    async updateFilter(newFilter) {
      this.filter = newFilter;
      this.tableLoadingState = true;
       try {
        await this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        this.tableLoadingState = false;
      }
       
    },
    toggleTable(table) {
      this.table = table;
    },
    closeDeleteDialog() {
      this.showConfirmDeleteDialog = false;
      this.selectedProject = null;
    },
    confirmDeleteProject(project) {
      this.showConfirmDeleteDialog = true;
      this.selectedProject = project;
    },
    async deleteProject() {
      try {
        await makeProjectService.deleteProject(this.handle, this.selectedProject.key);
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'projects' })
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'deleteError', { item: 'projects' });
      } finally {
        this.showConfirmDeleteDialog = false;
        this.closeDeleteDialog();
      }
    },

    async archiveProject() {
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
        },
        archived: true,
      };
      try {
        await makeProjectService.updateProject(this.handle, this.selectedProject.key, payload);
        showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Project' });
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'archiveError', { item: 'Project' });
      } finally {
        this.showConfirmArchiveDialog = false;
      }
    },

    async unarchiveProject() {
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
        },
        archived: false,
      };
      try {
        await makeProjectService.updateProject(this.handle, this.selectedProject.key, payload);
        showSuccessToast(this.$swal, 'unarchiveSuccess', { item: 'Project' });
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'unarchiveError', { item: 'Project' });
      } finally {
        this.showConfirmUnarchiveDialog = false;
      }
    },
    closeArchiveDialog() {
      this.showConfirmArchiveDialog = false;
      this.selectedProject = null;
    },
    confirmArchiveProject(project) {
      this.showConfirmArchiveDialog = true;
      this.selectedProject = project;
    },
    closeUnarchiveDialog() {
      this.showConfirmUnarchiveDialog = false;
      this.selectedProject = null;
    },
    confirmUnarchiveProject(project) {
      this.showConfirmUnarchiveDialog = true;
      this.selectedProject = project;
    },
    editItem(project) {
      this.selectedProject = project;
      this.$router.push({
        name: 'ProjectDetailView',
        params: {
          handle: this.$route.params.handle,
          key: project.key,
        },
      });
    },
    handleToggleStar(project) {
      this.selectedProject = project;
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
          star: !this.selectedProject.customFields.star,
        },
      };
      this.update({
        swal: this.$swal,
        handle: this.$route.params.handle,
        oldProject: this.selectedProject,
        payload,
      });
    },
    handleRouteChange(to, from, next) {
      this.getProjects({},to.params.handle);
      next();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(to, from, next);
  },
};
</script>
<style scoped>
.projects-loader {
  min-height: 500px;
}
</style>
